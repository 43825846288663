.App {
  margin: 40px 40px;
  background-color: #D9E3F1;
}

.container-imagePicker {
  width: max-content;
  font-size: 4rem;
  cursor: pointer;
  margin: 0 auto;
}

#input-imagePicker {
  display: none;
}

.buttongroup {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
}


.button {
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
}

.imageborder {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 30px;
}

.topimage {
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0 auto;
  max-width:250px;
}

.alert {
  padding-top: 10px;
  margin: 0 auto;
  text-align: center;
}